body {
  margin: 0%;
  font-family: Poppins;
}
.centerheadineg {
  text-align: center;
}

h3 {
  font-weight: 500;

  text-align: center;
}

.no-spin::-webkit-inner-spin-button,
.no-spin::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}

.no-spin {
  -moz-appearance: textfield !important;
}
/* card css/////////////////////////////////////////////////////////////////////////// */
.content {
  display: none;
}

.content.active {
  display: block;
  padding: 0px 60px;
}
.button-fl {
  display: flex;
  padding: 60px 80px;
  flex-wrap: wrap;
  justify-content: center;
}

.upcomingbutton1 {
  background-color: white;
  color: #232b70;
  font-size: 18px;
  padding: 11px 41px 11px 41px;
  box-shadow: 0px 0px 7px 0px #00000026;
  border: none;
  border-radius: 8px;
}

.upcomingbutton1.active {
  background-color: #232b70;
  color: white;
  font-size: 18px;
  margin-right: 20px;
  padding: 11px 41px 11px 41px;
}
.upcomingbutton {
  background-color: white;
  color: #232b70;
  margin-right: 20px;
  margin-bottom: 20px;
  font-size: 18px;
  padding: 11px 41px 11px 41px;
  box-shadow: 0px 0px 7px 0px #00000026;
  border: none;
  border-radius: 8px;
}

.upcomingbutton.active {
  background-color: #232b70;
  color: white;
  font-size: 18px;
  padding: 11px 41px 11px 41px;
}

button.active {
  background-color: blue;
  color: white;
  padding: 11px 41px 11px 41px;
}
/* 
  .button-container {
    text-align: center;
  } */

.button {
  margin: 0 10px 10px 0px;
  padding: 8px 16px;
  font-size: 16px;
  cursor: pointer;
  text-decoration: none; /* Remove underline for anchor elements */
  color: #fff; /* Set text color */
  background-color: #000b66; /* Set background color */
  border: 1px solid #007bff; /* Add border */
  border-radius: 4px; /* Add border radius for rounded corners */
}

.button:hover {
  background-color: #0056b3; /* Change background color on hover */
  border-color: #0056b3; /* Change border color on hover */
}

/* form for job apply////////////////////////////////////////////////////////////////// */

.formallrow {
  display: flex;
  justify-content: space-between;
}
.form-container {
  max-width: 600px;
  margin: 0 auto;
}

.form-group {
  margin-bottom: 15px;
  /* margin-left: 20px; */
  width: 100%;
}
.optinons {
  color: #c60937;
  font-size: 18px;
  font-weight: 400;
}
label {
  display: block;
  font-weight: bold;
  font-weight: 400;

  color: #808080;

  font-size: 18px;
  margin-bottom: 5px;
}

input[type="text"],
input[type="email"],
input[type="number"],
input[type="date"],
input[type="time"],
textarea,
.selecteres {
  font-family: Poppins;
  width: 94%;
  padding: 10px;
  border: 2px solid #8487a2;
  border-radius: 10px;
  box-sizing: border-box;
}
.form-buttoncentre {
  font-family: Poppins;
  display: flex;
  justify-content: center;
}
input[type="submit"] {
  background-color: #232b70;
  color: white;
  margin: 20px 0;
  padding: 11px 44px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-family: Poppins;
}

input[type="submit"]:hover {
  background-color: #232b70;
}
.hthree {
  text-align: center;
}
.formforrevnitro {
  display: flex;
  gap: 10px;
}
@media (min-width: 320px) and (max-width: 500px) {
  .content.active {
    display: block;
    padding: 10px 10px;
  }
  label {
    display: block;
    font-weight: bold;
    font-weight: 400;
    margin-left: 15px;
    color: #808080;
    text-align: left;
    font-size: 18px;
    margin-bottom: 5px;
  }

  .upcomingbutton {
    background-color: white;
    color: #232b70;
    margin-right: 10px;
    margin-bottom: 10px;
    font-size: 13px;
    padding: 8px 8px 11px 10px;
    box-shadow: 0px 0px 7px 0px #00000026;
    border: none;
    border-radius: 8px;
  }
  .button-fl {
    display: flex;
    padding: 20px 20px;
    flex-wrap: wrap;
    justify-content: center;
  }
  .column p {
    margin: 10px 0;
    color: #232b70;
    font-size: 12px;
  }
  .paraisnong {
    font-size: 14px;
  }
}

.EscalationButtonsFlexdiffjf {
  display: flex;
  justify-content: center;
  column-gap: 40px;
  margin-top: 50px;
  margin-bottom: 30px;
}
.ButtExcsjgdfbbmhfj {
  width: 172px;
  height: 52px;
  padding: 1px;
  gap: 10px;
  border-radius: 10px;
  border: none;
  box-shadow: 0px 0px 7px 0px #00000026;
  font-family: Poppins;
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  cursor: pointer;
}

@media (max-width: 1000px) {
  .EscalationButtonsFlexdiffjf {
    display: flex;
    justify-content: center;
    column-gap: 16px;
    margin-top: 50px;
    margin-bottom: 30px;
  }
  .ButtExcsjgdfbbmhfj {
    width: 77px;
    height: auto;
    padding: 1px;
    gap: 10px;
    border-radius: 4px;

    border: none;
    box-shadow: 0px 0px 7px 0px #00000026;
    font-family: Poppins;
    font-size: 9px;
    font-weight: 500;
    line-height: 12px;
    text-align: center;
    padding-top: 3px;
    padding-bottom: 3px;
    cursor: pointer;
  }
}

.NewAddedtexthaghdbdkjsdjkf {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  text-align: center;
  color: #808080;
}





.contact-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  color: #232B70;
  text-align: center;
}

.contact-details {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-left: 28%;
  color: #232B70;
  width: 56%;
}

.column {
  flex: 1;
  padding: 0 20px;
  text-align: left;
}

.column p {
  margin: 10px 0;
  color: #232B70;
}