body {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
  font-family: "Poppins";
}

.active {
  color: #f00 !important;
}

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #000;
  color: white;
  position: sticky;
  top: 0;
  padding-right: 20px;
  padding-bottom: 0px;
  z-index: 3;
}

nav .title {
  font-size: 16px;
  margin: 1rem;
  font-weight: bold;
  text-decoration: none;
  color: white;
  margin-bottom: 6px;
}

nav ul {
  display: flex;
}

nav ul li {
  list-style: none;
}

nav ul li a {
  display: block;
  text-decoration: none;
  color: #fff;
  margin: 0 0.5rem;
  border-radius: 6px;
  padding-left: 13px;
  padding-right: 13px;
  padding-top: 3px;
  padding-bottom: 3px;
  font-family: "Poppins";
  font-size: 17px;
  font-weight: 500;
  line-height: 29.06px;
}

nav ul li a:not(.active):hover {
  color: #f00 !important;
}

nav .menu {
  display: none;
  position: absolute;
  top: 22px;
  right: 1.7rem;
  flex-direction: column;
  justify-content: space-between;
  width: 2rem;
  height: 24px;
}

nav .menu span {
  height: 4px;
  width: 34px;
  background-color: #fff;
  border-radius: 0.2rem;
  margin-bottom: 0px;
}

@media (max-width: 900px) {
  .active {
  color: #f00 !important;
  }
  .ImageNavbrsixecontrol {
    height: 35px;
  }
  nav .menu {
    display: flex;
    text-align: center;
  }

  nav {
    flex-direction: column;
    align-items: flex-start;
    text-align: center;
  }

  nav ul {
    display: none;
    flex-direction: column;
    width: 100%;
    margin-bottom: 0.45rem;
    margin-top: 0px;
  }

  nav ul.open {
    display: flex;
    padding: 0px;
  }

  nav ul li {
    width: 100%;
    text-align: center;
  }

  nav ul li a {
    margin: 0.2rem 0.5rem;
    margin: 0.2rem 0.5rem;
    font-size: 14px;
    line-height: 23px;
  }
}